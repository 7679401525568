/*
 * Styles for /wp-login.php
 */
/* utils */
/**
 * This file imports all variables, mixins and functions. There should not be
 * anything that directly generates output.
 */
/* variables */
/**
 * Project variables
 *
 * All the Sass variables used throughout the theme. This file shouldn't output
 * any actual CSS on compilation.
 */
/* Colours */
/*
 * Colour variables.
 *
 * If you alter variable names, also update EditorColourPalette.php
 * These cascade to block editor colour palette.
 *
 * Sass variables need to be interpolated
 *
 * Don't use --text as this causes a collision with WordPress default classes.
 */
/*
 * Layout variables.
 *
 * Used for the wrapper and more layout options.
 */
/**
 * Z-Index variables.
 *
 * Z-Indicies should be defined in increments of 5 (avoid 99999999999 etc.)
 */
/* Typography */
/*
 * Breakpoint variables.
 *
 * Used for bp() mixin and bp() function
 */
/**
 * SVG icon defaults
 */
/**
 * Container Sizes
 */
/* mixins */
/*
 * Generate a media query based on convenient names such as sm, md , lg. The
 * names are set in a $breakpoints-map.
 *
 * Usage:
 *
 * @include bp(sm); // @media only screen and (min-width: 480px)
 *
 * @include bp($max: sm);  // @media only screen and (max-width: 480px)
 *
 * @include bp(sm, md);  // @media only screen and (min-width: 480px) and (max-width: 768px)
 *
 * @include bp(sm, $type: print);  // @media print and (min-width: 480px)
 *
 * @include bp(sm, md, print);  // @media print and (min-width: 480px) and (max-width: 768px)
 */
/**
 * Button mixins.
 *
 * For WordPress to work with buttons more efficiently, we seperate colours from
 * the rest of the button styles. This reduces the amount of duplicate CSS when
 * compiled.
 *
 * Buttons have the class .link-button.
 * Additional classes can be a combination of:
 *   .is-style-full
 *   .is-style-outline
 *   .has-[colour-name]-button-color
*/
/**
 * The button styles.
 */
/**
 * The colours for a regular button.
 */
/**
 * The colours for an outlined button.
 */
/* aliases - for the heathens who bastardise the English language */
/**
 * Checkbox override
 */
/**
 * This mixin targets Internet Explorer 10 and 11. Edge does not match with this
 * mixin.
 */
/**
 * Shop mixins.
 *
*/
/**
 * Shop price
 */
/* functions */
/**
 * Return the breakpoint from the map.
 */
/* reset */
/*
 * This is Andy Bell's modern CSS reset
 * Modern browsers don't need the normalize.css heavy approach
 * @link https://hankchizljaw.com/wrote/a-modern-css-reset/
 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: white;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
}

/* Natural flow and rhythm in articles by default
 * This is opinionated and intended to make working with component-based systems easier
 * If you don't know about the lobotomised owl, search that term for the ALA article
 * @link https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/
 */
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text--display-on-focus:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.login {
  background-color: #fff;
  background-color: var(--white, #fff);
  /* Set the size of the login logo */
  /* Hide the "Back to (website)" link */
  /* Hide the "Log in" link on the last password page, as it's pointless*/
  /* Center align the Lost Password link */
}
.login h1 a {
  background-image: url("../img/logo-login.png");
  background-size: 250px;
  width: 320px;
  height: 150px;
}
.login p#backtoblog {
  display: none;
}
.login.login-action-lostpassword p#nav {
  display: none;
}
.login.login-action-login p#nav {
  text-align: center;
}
.login input#wp-submit {
  font-weight: 600;
  padding: 0.5rem;
  border: 2px solid transparent;
  transition: all 0.25s ease;
  border-radius: 999px;
  -webkit-appearance: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  color: #fff;
  color: var(--primary-text, #fff);
  background-color: #e45e41;
  background-color: var(--primary, #e45e41);
  border-color: #e45e41;
  border-color: var(--primary, #e45e41);
  min-width: 100px;
  padding: 0.25rem;
}
.login input#wp-submit:hover, .login input#wp-submit:focus, .login input#wp-submit:active, .login input#wp-submit.active {
  text-decoration: none;
}
.login input#wp-submit:hover, .login input#wp-submit:focus, .login input#wp-submit:active, .login input#wp-submit.active {
  color: #fff;
  color: var(--primary-text, #fff);
  background-color: #f89938;
  background-color: var(--primary-hover, #f89938);
  border-color: #f89938;
  border-color: var(--primary-hover, #f89938);
}
.login .message {
  border-left-color: blue;
}
.login form {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.login .button-primary {
  text-shadow: none;
}
.login a {
  color: #e45e41;
  color: var(--link, #e45e41);
}
.login a:hover, .login a:focus, .login a:active {
  color: #ffa136;
  color: var(--link-hover, #ffa136);
}
.login p#nav a:hover, .login p#nav a:focus, .login p#nav a:active {
  color: #e45e41;
  color: var(--link, #e45e41);
}
.login .wp-pwd button.wp-hide-pw span::before {
  color: #f89938;
  color: var(--secondary, #f89938);
}
.login .button-primary:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #e45e41;
}
.login input[type=password]:focus,
.login input[type=text]:focus,
.login input[type=checkbox]:focus {
  border-color: #e45e41;
  border-color: var(--primary, #e45e41);
  box-shadow: 0 0 0 1px var(--primary, #e45e41);
}
.login input[type=checkbox]:checked::before {
  content: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%23e45e41%27%2F%3E%3C%2Fsvg%3E);
}

.wp-admin #wp-auth-check-wrap #wp-auth-check {
  background-color: white;
}