/*
 * Styles for /wp-login.php
 */
/* utils */
@import "utils/utils";

/* reset */
@import "common/reset";
@import "common/screen-reader";

$brand: blue;
$login-color: $brand;
.login {
  @include background-color(--white);
  /* Set the size of the login logo */
  h1 a {
    background-image: url("../img/logo-login.png");
    background-size: 250px;
    width: 320px;
    height: 150px;
  }
  /* Hide the "Back to (website)" link */
  p#backtoblog {
    display: none;
  }
  /* Hide the "Log in" link on the last password page, as it's pointless*/
  &.login-action-lostpassword p#nav {
    display: none;
  }
  /* Center align the Lost Password link */
  &.login-action-login p#nav {
    text-align: center;
  }
  input#wp-submit {
    @include button;
    @include button-colours(--primary);

    min-width: 100px;
    padding: 0.25rem;
  }
  .message {
    border-left-color: $login-color;
  }
  form {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
  }
  .button-primary {
    text-shadow: none;
  }
  a {
    @include colour(--link);

    &:hover,
    &:focus,
    &:active {
      @include colour(--link-hover);
    }
  }
  p#nav a {
    &:hover,
    &:focus,
    &:active {
      @include colour(--link);
    }
  }
  .wp-pwd button.wp-hide-pw span::before {
    @include colour(--secondary);
  }

  .button-primary:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #e45e41;
  }

  input[type="password"],
  input[type="text"],
  input[type="checkbox"] {
    &:focus {
      @include border-colour(--primary);
      box-shadow: 0 0 0 1px var(--primary, #e45e41);
    }
  }
  input[type="checkbox"]:checked::before {
    content: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%23e45e41%27%2F%3E%3C%2Fsvg%3E);
  }
}

// Match colours to modal shown when the login cookie expires
.wp-admin {
  #wp-auth-check-wrap #wp-auth-check {
    background-color: white;
  }
}
